import React, { Suspense } from "react";

import { RiLoader2Fill } from "react-icons/ri";
import "./styling/App.css";
const AppLazy = React.lazy(() => import("./AppLazy"));

function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div>
            <RiLoader2Fill id="LoadingLogo" title="Loading Logo" />
          </div>
        }
      >
        <AppLazy />
      </Suspense>
    </div>
  );
}

export default App;
